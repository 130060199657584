.button {
  position: relative;
  width: 70%;
  height: 3em;
  font-size: 3em;
  font-weight: bold;
  border-radius: 0.3em;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.3em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #1a1a1a;
}
.button::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -3px;
  width: 99%;
  height: 98%;
  border-radius: 0.3em;
  border: 2px solid #1a1a1a;
}
@media screen and (max-width: 767px) {
  .button {
    font-size: 2.5em;
  }
  .button::before {
    top: -3px;
    border: 1px solid #1a1a1a;
  }
}

.outer {
  padding: 3em;
  margin-bottom: 6em;
}
@media screen and (max-width: 767px) {
  .outer {
    padding: 1em 0;
  }
}
.container {
  position: relative;
  border: 1px solid #6fc299;
  border-radius: 0.3em;
  padding: 1em;
  width: calc(100vw - 8.2em);
  background-color: #fffded;
  aspect-ratio: 16/9;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .container {
    width: calc(100vw - 2.4em);
  }
}

.red__button {
  color: #1a1a1a;
  background-color: #ffcdcd;
}
.blue__button {
  color: #1a1a1a;
  background-color: #c1e2ff;
}
@media screen and (max-width: 767px) {
  .red__button,
  .blue__button {
    height: 2.5em;
  }
}

/* チャート */
.game-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}
.game-screen-top {
  display: flex;
  width: 100%;
  align-items: flex-end;
}
.chart__bg {
  width: 75%;
  height: calc(70vw * 0.5);
  background-color: #fff;
}
/* チャート右側 */
.chart__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 25%;
}
.now-score-label {
  font-size: 2em;
  white-space: nowrap;
  margin-bottom: 0.5em;
}
.now-score {
  font-size: 3em;
  font-weight: bold;
  color: #6fc299;
  margin-bottom: 1.5em;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .now-score {
    font-size: 2em;
  }
}
.money-box-label {
  font-size: 2em;
}
.money-box {
  width: 75%;
  font-size: 6em;
  font-weight: bold;
  color: #6fc299;
  border: 1px solid #6fc299;
  border-radius: 0.3em;
  padding: 0.2em 0.1em;
  margin-top: 0.2em;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .money-box {
    font-size: 4em;
    margin-bottom: 0em;
  }
}

/* font */
.small {
  font-size: 2.4em;
}
.medium {
  font-size: 3em;
}

@media screen and (max-width: 767px) {
  .small {
    font-size: 1.5em;
  }
  .medium {
    font-size: 2em;
  }
}
/* 背景 */
.bg-black {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing:border-box;
  height: 100%;
  width: 100%;
  padding: 1em;
  background: rgba(200, 200, 200, 0.7);
  z-index: 1;
}
.bg-result {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.3em;
  box-sizing:border-box;
  padding: 3em;
  height: 100%;
  background-color: #fffded;
  z-index: 3;
}
@media screen and (max-width: 767px) {
  .bg-result {
    padding: 1.5em;
  }
}

/* 画像 */
.result-img {
  position: absolute;
  width: 25%;
  height: auto;
}
.result-img-left {
  top: 0;
  left: 0;
}
.result-img-right {
  top: 5;
  right: 0;
}

/* 結果テキスト */
.result-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
@media screen and (max-width: 767px) {
  .result-text {
    gap: 1em;
  }
}
.result-text-price {
  color: #6fc299;
  font-size: 6em;
  font-weight: bold;
  margin: 0.5em 0;
}
@media screen and (max-width: 767px) {
  .result-text-price {
    font-size: 4em;
    margin: 0.2em 0;
  }
}
.result-text-bottom {
  display: inline-flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 1em;
}
.result-text-bottom p {
  text-align: start;
}

/* もう一度遊ぶ、もっと利益を出したい */
.result-button__outer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
@media screen and (max-width: 767px) {
  .result-button__outer {
    flex-direction: column;
    gap: 0.5rem;
  }
}
.result-button__again {
  color: #1a1a1a;
  background-color: transparent;
  border: 2px solid #000000;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5);
}
.result-button__link {
  width: 100%;
  background-color: #6fc299;
  color: #fff;
  text-decoration: none;
  line-height: 250%;
}
@media screen and (max-width: 767px) {
  .result-button__again {
    height: 2em;
  }
  .result-button__link {
    width: 70%;
    height: 2em;
  }
}
